@import '../../scss/variables.scss';
.profile-step {
  max-width: 760px;
  margin: 24px auto;
  @media (max-width: 768px) {
    margin: 0 auto !important;
  }

  .parent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    // grid-template-rows: repeat(2, 1fr);
    gap: 16px; // Uniform gap for all directions

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, auto);
    }

    @media (max-width: 480px) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(5, auto);
    }
  }

  .box1 {
    grid-area: 1 / 1 / 3 / 2;
    border: 1px solid $shark-grey;
    border-radius: 12px;
    width: 100%;
    min-height: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 4px;

    h5 {
      font-size: 24px;
      text-align: center;
      font-weight: 500;
      color: $text-color;
      padding-bottom: 20px;
    }

    @media (max-width: 768px) {
      grid-area: 1 / 1 / 2 / 3;
      gap: 10px;
      padding-bottom: 10px;
    }

    @media (max-width: 480px) {
      grid-area: 1 / 1 / 2 / 2;
    }
  }

  .box2,
  .box3,
  .box4 {
    width: 100%;
    height: auto;
    max-height: 100%;
    border-radius: 10px;
    border: 1px solid $shark-grey;
    position: relative;

    .profile-box-2 {
      display: grid;
      align-items: center;
      grid-template-columns: 20% 80%;
      padding-top: 19px;
      justify-items: flex-start;
      gap: 5px;
      padding-left: 4px;
    }

    .text-2-profile {
      text-align: center;
      padding-top: 20px;
      font-size: 18px;
      line-height: 1.3;
      padding-bottom: 10px;
      @media(max-width:548px){
        font-size: 16px;

      }
      @media(max-width:768px){
        text-align: left;
        margin-left: 69px;
        padding-top: unset;
        
      }
    }

    .border-light-blue-profile,
    .border-light-skin-color-profile,
    .border-light-voilet-color-profile {
      position: absolute;
      width: 100%;
      height: 10px;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 0 0 12px 12px;
    }
  }
  .edit-icon{
    @media (max-width:1287px){
      font-size: 12px;
    }
  }
  .box2 {
    grid-area: 1 / 2 / 2 / 3;

    .border-light-blue-profile {
      background-color: $magic-mint;
    }

    @media (max-width: 768px) {
      grid-area: 2 / 1 / 3 / 2;
    }

    @media (max-width: 480px) {
      grid-area: 2 / 1 / 3 / 2;
    }
  }

  .box3 {
    grid-area: 1 / 3 / 2 / 4;

    .border-light-skin-color-profile {
      background-color: #FFF1C1;
    }

    @media (max-width: 768px) {
      grid-area: 2 / 2 / 3 / 3;
    }

    @media (max-width: 480px) {
      grid-area: 3 / 1 / 4 / 2;
    }
  }

  .box4 {
    grid-area: 1 / 4 / 2 / 5;

    .border-light-voilet-color-profile {
      background-color: #E1DBFF;
    }

    @media (max-width: 768px) {
      grid-area: 3 / 1 / 4 / 2;
    }

    @media (max-width: 480px) {
      grid-area: 4 / 1 / 5 / 2;
    }
  }

  .box5 {
    grid-area: 2 / 2 / 3 / 5;

    @media (max-width: 768px) {
      grid-area: 4/ 1 / 4 / 3;
    }

    @media (max-width: 480px) {
      grid-area: 5 / 1 / 6 / 2;
    }
  }
}

.tab-container {
  width: 760px;

  max-width: 100%;
  top: 61px;
  gap: 0px;
  border-radius: 41.4px;

  border: 1px solid #C0C0C0 !important;
  background-color: #ebffed;

 
  margin-bottom: 70px;
  @media screen and (max-width:778px) {
    border: 1px solid #C0C0C0 !important;
    
  }
  @media screen and (max-width:578px) {
    border-radius: 15.4px;
  }
}
.tab-profile-game{

 >div{
  display: flex;
  flex-direction: column;
  align-items: center;

  >div>div>div>div{
    gap:10px;
  
  }
  @media screen and (max-width:798px) {
    display: block;
  
    
  }
 }

 



}

.profilefill::after{
  width: 22px;
  height: 22px;
}
.profiletextfill{
  top: 1px !important;
  font-size: 9px !important;

}




.member-1.Mui-selected, .member-2.Mui-selected, .member-3.Mui-selected {
    background-color: #2a9235 !important;
   border-radius: 4px !important;
    border: none !important;
    color: #fff !important;
}

.member-1, .member-2, .member-3 {
    background-color: #ffffff !important;
    border-radius: 4px !important;
    border: none !important;
    color: #000000 !important;
}
.member-profile-payment{
    margin-top: 35px !important;
    width: 80%;
    margin: 0 auto;
    @media (max-width:598px) {
        width: 100%;
        
    }
   

}
.member-starting{
    border: 1px solid #D7D7D7;
    background-color: #F3F3F3;
    border-radius: 8px !important;
    padding: 8px 10px;
    width: 100%;

    @media(max-width:468px) {
        padding: 5px 4px !important;
        
    }
  
}

.member-starting > div > div {
    gap: 10px;

    button{
        flex: 1;
        white-space: nowrap;
        padding: 2px 16px;
        @media(max-width:468px) {
            font-size: 12px !important;
            
        }
    }    
}
.member-starting > div{
    margin: auto;
    width: 80%;
}

.subscribe-btn{
    max-width: 100%;
    width: 500px;
    height: 60px;
    border-radius: 8px !important;
    margin-top: 30px;
}
.logo-membership{
    width: 60px;
    @media(max-width:768px) {
        width: 50px;
        
    }
}
.experience-text-font{
    font-size: 20px !important;
    flex-basis: 68%;
    @media (max-width:798px) {
        font-size: 18px !important;
        
    }
}
.price-text{
    font-size: 40px !important;
    @media (max-width:798px) {
        font-size: 28px !important;
        
    }
}
.ad-free-text{
font-size: 24px !important;
@media (max-width:568px) {
    font-size: 20px!important;
    
}
}
.edit-heading-text{
    font-size: 24px !important;
    @media(max-width:598px){
        font-size: 20px !important;
    }
    @media(max-width:398px){
        font-size: 18px !important;
    }

}
.change-psd{
    margin: 24px auto !important;
}
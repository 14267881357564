@import '../../scss/variables.scss';

.tab-1,
.tab-2,
.tab-3,
.tab-4,
.tab-5 {
  background-color: #ffffff !important;

  border-radius: 16.5px 16.5px 0 0 !important;
  border: 1.07px solid #c0c0c0 !important;
  color: #000000 !important;
}
.tab-1.Mui-selected,
.tab-2.Mui-selected,
.tab-3.Mui-selected,
.tab-4.Mui-selected,
.tab-5.Mui-selected {
  background-color: #2a9235 !important;
  border-radius: 16.5px 16.5px 0 0 !important;
  border: 2.07px solid #c0c0c0 !important;
  color: #fff !important;
}
.tab-starting {
  button {
    font-weight: 300 !important;
  }
  span {
    background-color: unset;
  }
  div:nth-child(4) {
    @media screen and (max-width: 599.95px) {
      display: block !important;
      margin: auto !important;
    }
  }
}

.tab-inside-content {
  .tab-flex-text {
    display: flex;
    gap: 12px;
    align-items: center;
    flex-wrap: nowrap;
    border: 1px solid #d1d1d1;
    background-color: #fff;
    width: 675px;
    max-width: 100%;
    border-radius: 12px;
    padding: 7px 0px 7px 0px;
    margin-top: 15px;
    height: 78px;
    @media screen and (max-width: 576px) {
      height: 68px;
    }
  }
  .tab-lock-text {
    justify-content: space-between;
    background-color: #d3d3d3;
    flex-wrap: nowrap;
  }

  .tab-second-text {
    @media screen and (max-width: 472px) {
      font-size: 20px !important;
    }
  }
}

.grid-badges {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 14px;
  padding: 20px;
  justify-items: center;
  @media screen and (max-width: 998px) {
    grid-template-columns: repeat(3, 1fr);
  }
  // @media screen and (max-width: 445px) {
  //   grid-template-columns: repeat(2, 1fr);
  // }
}
.lock-badges {
  position: absolute;

  top: 34%;
  left: 51%;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 998px) {
    top: 38%;
  }
  @media screen and (max-width: 965px) {
    top: 40%;
  }
  @media screen and (max-width: 765px) {
    top: 34%;
  }
  @media screen and (max-width: 529px) {
    top: 31%;
  }
  @media screen and (max-width: 463px) {
    top: 24%;
  }
  @media screen and (max-width: 460px) {
    top: 33%;
  }
  @media screen and (max-width: 450px) {
    top: 34%;
  }
  @media screen and (max-width: 430px) {
    top: 32%;
  }
  @media screen and (max-width: 337px) {
    top: 24%;
  }
  @media screen and (max-width: 331px) {
    top: 31%;
  }
  @media screen and (max-width: 321px) {
    top: 32%;
  }
}
.avatar-image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-items: center;
  row-gap: 12px;
  padding-top: 24px;
}
.badges-name {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.membership-profile {
  ul {
    li {
      padding: 0;
      color: #454545;
      > div {
        min-width: unset;
      }
    }
  }
  .membership-ad {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 23px;
  }
}
.cancel-membership-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
}

.green-boxes {
  background-color: #2a9235;

  position: relative;
  clip-path: polygon(-204% 0%, 300% 0%, 50.8% 89.3%);
  box-shadow: 0px -11.9px 11.9px 0px rgba(0, 0, 0, 0.2509803922) inset;
  border-radius: 16px 16px 0 0;
}
.circle-inside-box,
.circle-inside-box-topper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 216px;
  padding-top: 20px;
  @media screen and (max-width: 410px) {
    height: 188px;
  }
}
.circle-inside-box-topper {
  height: 254px;

  @media screen and (max-width: 410px) {
    height: 203px;
  }
}
.players-score,
.players-score-topper {
  position: absolute;
  background: #ffe277;
  height: 60px;
  width: 60px;
  bottom: 25%;
  margin: 0 auto;
  border-radius: 50%;
  left: 34%;
  border: 6px solid #2a9235;
  color: green;
  font-size: 32px;
  font-weight: 400;
  text-align: center;

  @media screen and (max-width: 698px) {
    height: 48px;
    width: 48px;
    font-size: 25px;
  }

  @media screen and (max-width: 410px) {
    height: 38px;
    width: 38px;
    font-size: 18px;
  }
}
.players-score-topper {
  bottom: 50px;
  left: 37%;
}

.Green-leaderboard-box {
  display: grid;
  grid-template-columns: 1fr 1.3fr 1fr;
  gap: 30px;
  min-height: 298px;
  @media (max-width: 698px) {
    gap: 7px;
  }
  @media (max-width: 410px) {
    gap: 7px;
    min-height: 238px;
  }
  .player-1,
  .player-2,
  .player-3 {
    font-size: 15px;
    @media (max-width: 512px) {
      font-size: 13px;
    }
    @media (max-width: 430px) {
      font-size: 11px;
    }
  }
  .player-1-subtitle,
  .player-2-subtitle,
  .player-3-subtitle {
    font-size: 22px;
    @media (max-width: 512px) {
      font-size: 20px;
    }
    @media (max-width: 430px) {
      font-size: 17px;
    }
  }
}
.leaderboard-flex-parent {
  background: white;
  border: 2px solid #dddddd;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  .leaderboard-flex-child {
    display: flex;
    align-items: center;
    gap: 35px;
  }
}
.profile-leaderboard {
  @media (max-width: 430px) {
    padding: 11px !important;
  }
}
#simple-tabpanel-3 > div {
  @media (max-width: 678px) {
    padding: 12px;
  }
  @media (max-width: 410px) {
    padding: 12px;
  }
  @media (max-width: 378px) {
    padding: 6px;
  }
}
.lock-avatar {
  position: relative;

  .lock-icon {
    position: absolute;
    top: 29px;
    bottom: 0;
    left: 27px;
    @media (max-width: 755px) {
      left: 24px;
    }
    @media (max-width: 676px) {
      left: 22px;
    }
    @media (max-width: 552px) {
      left: 25px;
      top: 25px;
    }
    @media (max-width: 460px) {
      left: 8px;
      top: 12px;
    }
    @media (max-width: 456px) {
      left: 25px;
      top: 25px;
    }
    @media (max-width: 381px) {
      left: 22px;
      top: 27px;
    }
    @media (max-width: 352px) {
      left: 15px;
      top: 16px;
    }
  }
}

.tab-inside-content,
.grid-badges,
.avatar-image-grid {
  overflow-y: scroll;
  scrollbar-width: thin;
  height: 500px;
  scroll-padding-right: 14px;
  margin-top: 13px;
  padding-right: 13px;
}
.membership-current {
  font-size: 28px !important;
  @media screen and (max-width: 968px) {
    font-size: 22px !important;
  }
  @media screen and (max-width: 768px) {
    font-size: 18px !important;
  }
}
.benefit-ad {
  font-size: 23px !important;
  @media screen and (max-width: 768px) {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 500px) {
  .tab-conatiner-progress > div > div {
    padding: 12px !important;
  }
  .level-container > div > div {
    padding: 12px !important;
  }
}

.tab-text-container {
  font-size: 18px !important;
  line-height: 1.2;
  @media (max-width: 576px) {
    font-size: 14px !important;
  }
  @media (max-width: 376px) {
    font-size: 12px !important;
  }
}
.manage-btn-member{
  width: 460px;
  max-width: 100%;
  height: 49px;
  border-radius: 8px !important;
}
.list-membership{
  padding-left:20px;
  list-style-type: disc;
  line-height: 2;
  margin-top: 20px;
  margin-bottom: 20px;
  li{
color: #454545;
font-weight: 500;
font-size: 18px;
@media (max-width: 768px) {
  font-size: 16px !important;
}
  }
}
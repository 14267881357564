@import '../scss/variables.scss';

.result-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 34px;

  @media screen and (max-width: 505px) {
    justify-content: center;
  }
  @media screen and (max-width: 650px) {
    flex-wrap: nowrap;
    gap: 10px;
  }
  button {
    border-radius: 28px;
    text-transform: none;
    font-size: 20px;
    /* max-width: 200px; */
    width: 100%;
    padding: 12px;
    flex-basis: 50%;
    font-family: 'Poppins';
  }

  .btn-icon-result {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: center;
    font-size: 19px;
    @media (max-width: 670px) {
      font-size: 16px;
      gap: 4px;
    }
    @media (max-width: 498px) {
      font-size: 14px;
    }
    @media (max-width: 422px) {
      font-size: 12px;
    }
    @media (max-width: 348px) {
      font-size: 10px;
    }
  }
}
.result-current-level {
  background-color: #f5efe8;
  border: 1.84px solid #cbcbcb;
  max-width: 100%;
  text-align: center;
}
.result-step {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 57px;
  @media (max-width: 670px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  .result-create-account {
    background-color: $orange-dark;
    border-radius: 12px;
    // display: flex;
    // align-items: center;
    // justify-content: space-evenly;
    text-align: left;
    gap: 6px;
    padding: 7px 12px 12px 12px;

    // flex-wrap: nowrap;
    .login-result {
      @media screen and (max-width: 675px) {
        font-size: 18px;
    
      }
      @media screen and (max-width: 475px) {
        font-size: 14px;
      }
    }
    .create-btn-result {
      max-width: 22ch;
      flex-basis: 50%;
      @media screen and (max-width: 671px) {
        max-width: unset;
        font-size: 16px;
        flex-basis: 42%;
      }
      
      @media screen and (max-width: 475px) {
        font-size: 11px;
      }
      @media screen and (max-width: 383px) {
        font-size: 9px;
      }
    }
    .create-btn {
      &:hover {
        background-color: $white;
      }
      @media screen and (max-width: 475px) {
        font-size: 11px;
      }
    }
    .login-btn {
      @media screen and (max-width: 475px) {
        font-size: 11px;
      }
      @media screen and (max-width: 383px) {
        font-size: 9px;
      }
    }
    button {
      &:hover {
        background-color: unset;
      }
    }
  }
  .profile-name {
    position: absolute;
    top: 30%;
    right: 0;
    left: 38%;
    width: 140px;

    @media screen and (max-width: 698px) {
      width: 122px;
    }
    @media screen and (max-width: 520px) {
      width: 90px;
    }
    @media screen and (max-width: 472px) {
      top: 30%;
    }

    @media screen and (max-width: 398px) {
      width: 65px;
      top: 30%;
      left: 39%;
    }
    @media screen and (max-width: 396px) {
      width: 76px;
      top: 30%;
    }
    @media screen and (max-width: 332px) {
      top: 30%;
      left: 34%;
    }
  }
  .text-congrats {
    font-size: 31px;
    position: absolute;
    top: 8%;
    left: 0;
    right: 0;
    @media screen and (max-width: 670px) {
      font-size: 24px;
    }

    @media screen and (max-width: 320px) {
      font-size: 18px;
      top: 4%;
    }
  }
}
.w-try {
  @media (max-width: 398px) {
    max-width: 110px !important;
  }
}
.result-step{
  margin: 24px auto !important;
}
.result-orange-box{
 flex-basis: 58%;
}
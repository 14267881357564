@import '../../scss/variables.scss';

.headerBanner {
  background-color: $green;
  margin-top: 65px;

  @media (min-width: 992px) {
    margin-top: 77px;
  }
  @media (max-width: 1199px) {
    margin-top: 65px !important;
  }
  @media (max-width: 798px) {
    display: none;
  }
  .user-score-points {
    padding: 10px 0;
    align-items: flex-start;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    color: $white;
    grid-column-gap: 20px;
    grid-row-gap: 30px;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 992px) {
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    }
    @media (min-width: 1280px) {
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    }
    @media (min-width: 1440px) {
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    }
    .user-profile {
      img {
        &.profilePic {
          max-width: 100px;
          display: block;
          margin: 0 auto;
        }
      }
      .user-name {
        font-size: 28px;
        line-height: 1.15;
        font-weight: 600;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .score-details {
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      justify-content: center;
      .title {
        font-size: 24px;
        font-weight: 500;
        line-height: 1.2;
      }
      p {
        font-size: 18px;
        line-height: 1.2;
      }
      .progress {
        background-color: $white;
        color: $text-color;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.4;
        border-radius: 8px;
        overflow: inherit;
        align-items: center;
        .points {
          background-color: $amber;
          text-align: right;
          width: 50%;
          padding-right: 10px;
          border-radius: 8px 0 0 8px;
        }
        .credits {
          width: 50%;
          padding-right: 10px;
          text-align: right;
        }
        .seperator {
          min-width: 22px;
          height: 22px;
          border-radius: 50%;
          background-color: $amber;
          border: 2px solid $text-color;
          text-align: center;
          margin-left: -5px;
        }
      }
    }
    .score-point {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      // row-gap: 8px;
      height: 100%;
      .title {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
        @media screen and (max-width: 1440px) {
          font-size: 18px;
        }
        @media screen and (max-width: 1366px) {
          font-size: 16px;
        }
        &.bottom {
          // min-height: 40px;
          display: flex;
          align-items: baseline;
          justify-content: end;
          flex-direction: column;
        }
      }
      .badge-img {
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .user-profile {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
.header-quiz {
  display: none !important;

  @media (max-width: 798px) {
    display: block !important;
  }

  .greenbar-no-header{
    @media (max-width: 798px) {
      display: none !important;

    }
  }

  .mobile-responsive-quiz {
    background-color: #2a9235;
    width: 100%;
    border-radius: 24px;
    padding: 16px 12px 20px 12px;
    margin-top: 78px;
    max-width: 100%;
    margin-bottom: 13px;

    .header-quiz-response {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      text-align: center;
      justify-content: center;
    }

    // progress bar css start

    .progress-line-bar {
    //   margin: 9px 0;
      height: 6px;
      width: 100%;
      max-width: 208px;
      background-color: #ff0707;
    }

    // progress bar css end
  }
}

.customprogress {
  height: 14px;
  border-radius: 30px;
  background-color: #fff;
  margin: 13px 0;
  position: relative;
  max-width: 100%;
  width: 100%;
}

.innerFill {
  background-color: #ffc107;
  border-radius: 30px;
  height: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &::after {
    content: '';
    width: 20px;
    height: 20px;
    // left: auto;
    background-image: url('../../../assets/img/levelstar.svg');
    background-size: contain;
    right: 0;
    top: -3px;
    position: absolute;
    background-repeat: no-repeat;
  }
}

// .resultfill{
//     &::after{
//         width: 20px;
//         height: 25px;
//         // left: auto;

//     }
// }
.sliderfill {
  &::after {
    width: 15px;
    height: 15px;
    top: -4px;
  }
}
.custmPrgsTxt {
  font-size: 9px !important;
  color: $green-text;
  text-align: right;
  padding-right: 28px;
  font-weight: 600 !important;
  flex: 1;
  white-space: nowrap;
}

.custmPrgsTxtunfill {
  position: absolute;
  top: 1px;
  right: 0;
  font-size: 9px !important;
  padding-right: 16px;
  font-weight: 600 !important;
  color: $red-dark;
  white-space: nowrap;
  font-family: 'Poppins', sans-serif;
}

.image-width {
  height: 15px;
  width: 15px;
}

.current-level-header {
  @media (max-width: 1440px) {
    font-size: 18px !important;
  }
  @media (max-width: 1366px) {
    font-size: 18px !important;
  }
}
.customtooltip {
  height: 12px;
  width: 36px;
  background-color:$white;
  position: absolute;
  top: -19px;
  right: -8px;
  z-index: 999999;
  color: #333333;
  text-align: center;
  font-size: 10px;
  border-radius: 3px;
  opacity: 0;
  visibility: hidden;
  z-index: 99;


}

.customtooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
}

.innerFill:hover .customtooltip {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 798px) {
  .customtooltip {
    opacity: 1;
    visibility: visible;
  }
}


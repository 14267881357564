@import '../../scss//variables.scss';

.navbar-new-section {
  .css-1f5y6oa-MuiButtonBase-root-MuiIconButton-root:hover {
    background-color: unset !important;
  }
  .navbar-content {
    display: flex;
    justify-content: center;

    a {
      color: $scorpion;
      font-size: 18px;
      padding-right: 52px;
      white-space: nowrap;
      text-transform: none;
    }

    @media (max-width: 1199px) {
      display: none !important;
    }
  }
  .logo-company {
    @media (max-width: 1199px) {
      flex: 6;
    }
    img{
      max-width: 200px;
      @media screen and (max-width:558px) {
        max-width: 135px;
        
      }
    }

  }

  .css-1ldmmrg-MuiButtonBase-root-MuiButton-root:hover {
    background-color: unset !important;
  }

  .css-1vq64ow {
    @media screen and (max-width: 960px) {
      top: 54px;
    }
  }


  .top-navbar {
    background-color: #fff;
    border-bottom: 1px solid $silver-chalice;
    @media (max-width: 991px) {
      max-height: 90vh;
      overflow-y: auto;
    }
    .navbar {
      .navbar-brand {
        max-width: 135px;
        img {
          max-height: 50px;
        }
      }
      .navbar-nav {
        padding: 16px 0;
        .nav-item {
          @media (min-width: 992px) {
            margin: 0 20px;
          }
          .nav-link {
            font-size: 18px;
            color: $scorpion;
            padding: 12px 0;
            font-weight: 500;
            &.active,
            &:hover,
            &:focus {
              color: $green;
            }
          }
        }
      }
    }
    .user-profile {
      color: $green;
      font-size: 16px;
      font-weight: 500;
      .user-title {
        max-width: 100px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 1.2;
      }
      img {
        max-width: 50px;
        border: 1px solid $white-gray;
      }
    }
    .navbar-sub-toggler {
      border: none;
      padding: 5px;
      box-shadow: none !important;
      .navbar-toggler-icon {
        width: 1em;
        height: 1em;
        background-size: 118%;
      }
    }
    .nav-subItem {
      background-color: $beg-gray;
      .navbar-nav {
        padding: 15px 0;
        @media (min-width: 992px) {
          display: flex;
          flex-basis: auto;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
        .nav-item {
          @media (min-width: 992px) {
            margin: 0 10px;
          }
          @media (min-width: 1200px) {
            margin: 0 20px;
          }
          .nav-link {
            font-size: 17px;
            color: $scorpion;
            padding: 12px 0;
            @media (min-width: 1200px) {
              font-size: 18px;
            }
            &.active,
            &:hover,
            &:focus {
              color: $green;
            }
          }
        }
      }
    }
    .navbar-toggler {
      &:not(.navbar-sub-toggler) {
        width: 35px;
        height: 35px;
        padding: 0;
        border: none;
        outline: none;
        box-shadow: none;
        .navbar-toggler-icon {
          background-image: none;
          position: relative;
          width: 100%;
          height: 4px;
          background-color: $green;
          transition: all 0.3s;
          &:before,
          &:after {
            content: "";
            position: absolute;
            left: 0;
            top: -9px;
            width: 100%;
            height: 4px;
            background-color: $green;
            transition: all 0.3s;
            backface-visibility: hidden;
          }
          &:before {
            top: 9px;
          }
        }
      }
      &:not(.collapsed) {
        .navbar-toggler-icon {
          background-color: transparent;
          &:before {
            transform: rotate(45deg) translate(-7px, -8px);
          }
          &:after {
            transform: rotate(-45deg) translate(-5px, 5px);
          }
        }
      }
    }
  }
}
.navbar-top-content{
  display :none;

  @media screen and (max-width: 960px) {
    display: block ;
  }
}
.navbar-upper-content-list{
  @media screen and (max-width: 974px) {
    flex-wrap: wrap; 

  } 
}
.scroll-navbar {
  a{
    color:#5E5E5E;
  }
  @media screen and (max-width: 974px) {
    max-height: 206px;
    overflow-y: auto; 
  }
 
  scrollbar-width: 10px; 
  scrollbar-color:#D9D9D9; 

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #D9D9D9;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 10px;
    border: 2px solid #fff;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}
.navbar-new-section{
  box-shadow: none !important;
  border-bottom: 1px solid #A3A3A3
  
}
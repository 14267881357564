

.progress-box-parent {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
    place-items: center;
  
    @media screen and (max-width:422px) {
        grid-template-columns: 1fr;
        
    }

.progress-box{
    border: 1px solid #BBBBBB;
    border-radius: 12px;
    width: 100%;
    margin-top: 12px;
    padding: 8px;
    height: 100%;
img{

    @media (max-width:422px) {
        width: 12%;
        
    }
}
   
}
}
.performance-progress{
    width: 100%;
    max-width: 760px;
    margin: 0px auto;
    margin-bottom: 20px;
    border-radius:24px;
    background-color: #F5EFE8;
    border: 1.07px solid #D1D1D1;

    @media screen and (max-width:656px) {
        border-radius: 16px !important; 
    } 
    
      
    
}
.progress-border-section{
    padding: 24px;
    @media (max-width:768px) {
        padding:8px !important;
        
    }

}
.progress-word{
    justify-content: center;
}
.progress-inside-box{
width: 100% !important;
max-width: 104px;
height: unset !important;
}
.progress-btn{
    background-color: #fff !important;
    margin-left: 34px !important;
    @media (max-width:498px){

        font-size: 16px !important;
    }
    @media (max-width:400px){

       margin-left: unset !important;
    }
    @media (max-width:357px){
        margin-left: unset !important;
        font-size: 14px !important;
    }
   
    &:hover{
        border: 1.07px solid #D1D1D1 !important;
    }
}
.w-640{
    width:  100% !important;
    max-width: 760px !important;
    margin: 0px auto;

  }
  .progress-word{
    @media screen and (max-width: 698px) {
       
            grid-template-columns: repeat(4, 1fr);
        
    }
    @media screen and (max-width: 400px) {
       
        grid-template-columns: repeat(3, 1fr);
    
}
  }

  .progress-border-value{
    @media screen and (max-width:456px) {
        font-size: 15px !important;
        
    }
  }
  .progress-colorful-value{
    span{
        @media screen and (max-width:456px) {
            font-size: 13px !important;
            
        }  
    }
  }
  .progress-word 
  {
  .progress-detail{
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    width: 90px !important;
    background: #fff;
    max-width: 100%;

    
  }

  }
  .progress-text-detail{
    padding-bottom: 24px;
 
    p{
        @media (max-width:667px) {
            font-size: 18px !important;
            
        }
        @media (max-width:467px) {
            font-size: 16px !important;
            
        }
    }
  }
 .progress-heading-top{
    @media (max-width:667px) {
        font-size: 18px !important;
        padding-bottom: 9px;
        
    }
    @media (max-width:467px) {
        font-size: 16px !important;
        padding-bottom: 9px;
        
    }
    
 }
 .progress-step{
    margin:24px auto ;
    max-width: 760px !important;
    h3{
        @media (max-width:667px) {
            font-size: 20px !important;
  
            
        }
       
    }
 }

 .current-level-para{
    font-size: 20px !important;
    @media (max-width:667px) {
        font-size: 18px !important;

        
    }  
    @media (max-width:458px) {
        font-size: 16px !important;

        
    }  
 }
 .current-level-word{
    font-size: 24px !important;
    padding-bottom: 4px;
    @media (max-width:667px) {
        font-size: 18px !important;

        
    }  
    @media (max-width:458px) {
        font-size: 16px !important;

        
    }  
 }
 .performance-progress{
    img{
        position: absolute;
        right: 8px;
        width: 4%;
        top: 2px;
        @media (max-width:500px) {
            width: 4%;
            
        }
        @media (max-width:398px) {
            width: 6%;
            
        }
    }
 }
 .gap-progress{
    @media screen and (max-width:467px) {
        gap:72px !important
        
    }
 }
.progress-inside-search{
 display: flex;
 flex-direction: column;
 border-radius: 8px;
 width: 120px;
 background: #fff;
 height: 71px;
}

.score-container{
    padding: 6px 35px 30px 36px !important ;
@media (max-width:698px) {
    padding:  6px 11px 18px 11px !important

}
  
}

.total-score-font{
    @media (max-width:568px) {
        font-size: 14px !important;
        
    }
}
.score-font-para {
    @media (max-width:568px) {
        font-size: 24px !important;
        
    }
    @media (max-width:422px) {
        margin-left: 51px !important;
        text-align: left !important;
        margin-top: unset !important;
        
    }
}
.phonogram-heading{
    @media (max-width:400px) {
        display: flex;
        justify-content: center;
        
    }
}
.progress-border-section {
    p{
        padding-top: 12px;
    }
}
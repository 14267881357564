$green: #2A9235;
$green-light: #CFF1D3;
$green-dark: #307537;
$scorpion: #5E5E5E;
$silver-chalice: #A3A3A3;
$gray: #878787;
$text-color: #333333;
$white: #fff;
$white-gray: #EDEDED;
$beg-gray:#EBEBEB;
$black: #000;
$scorpiont: #585858;
$royal-blue: #23A6F0;
$slate-gray:#7B8794;
$boston-blue: #3C91B7;
$amber: #FFC107;
$picton-blue: #379AE6;
$ecru-white: #FBFCF7;
$silver-chalice: #9D9D9D;
$cinnabar: #E94E32;
$sky-blue:#29B9FF;
$panache: #E5F3E6;
$dusty-gray: #949494;
$orange-fade:#E94E3299;
$redish:#EC1C24;
$red-dark:#CF232A;
$pinkish:#F4777C;
$greenish:#9FFFA9;
$greynish:#DFDFDF;
$orange-dark:#F68327;
$white-dark:#F5F5F5;
$green-light:#5FCD6A4D;
$grey-light:#DADADA;
$border-green:#2A9235;
$black-border:#3A3A3A;
$sky-light:#C2F6E3;
$green-text:#30A13B;
$magic-mint:  #c2f6e3;
$shark-grey:#959fa9;
$bg-green-gradient: linear-gradient(157.79deg, #DCF4DF 0%, #8DDF96 100%);
$bg-antywhite-gradient: linear-gradient(89.58deg, #F1F4F2 0.47%, #EBF4EC 99.74%);
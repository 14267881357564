@import '../../scss/variables.scss';
.tab-progress.Mui-selected{
    background-color:#FFC107 !important;

}

.tab-starting{
    button{
        text-transform: none;
        font-size: 18px !important;
        @media  (max-width:599px) {
            font-size: 14px !important;
            
            
        }
    }
}
.tab-conatiner-progress{
    background-color:  $white !important;
    border-radius: 21px !important;

}
.progress-tab-game > div > div > div > div > div >div {
gap: 8px !important;
}
.progress-tab-game > div > div > div  {
border-bottom: unset;
    }

    td{
        width: 50%;
        
    }

    .tab-progress{
        font-family:   Mulish;
    }
    .table-heading{
        @media screen and (max-width:499px) 
        {
       gap:32px !important;
      
        }
        p{
            @media screen and (max-width:599px) 
            {
            font-size: 16px;   
   
            white-space: nowrap;
          
            }
            @media screen and (max-width:472px) 
            {
            font-size: 14px;   
   
          
            }
        }
    }
    .first-cell,.second-cell{
        @media screen and (max-width:599px) 
        {
        font-size: 16px !important;    
        }
    }
    .tab-progress.Mui-selected
 {

  color: #333333 !important;
  font-weight: bold !important;
}
@import '../../scss/variables.scss';
.footer-section{
    color: $text-color;
    padding: 28px 0px 30px 0px;
    border-top: 1px solid $silver-chalice;
    // @media (min-width: 992px) {
    //     padding: 45px 0;
    // }
    @media (max-width: 1199px) {
        font-size: 15px;
    }
    p{
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        margin-bottom: 12px;

        @media (max-width:457px) {
            font-size: 13px !important;
        }
        &.copyright{
            font-size: 12px;
        }
    }
    &__title{
        font-size: 20px !important;
        font-weight: 700;
        margin-bottom: 15px;

        @media (max-width:598px) {
            font-size: 16px !important;
        }
    }
    &__email{
        font-weight: 700;
    }
    &__appdownload{
        background-color: $green-light;
        padding: 12px 15px;
        border-radius: 28px;
        column-gap: 10px;
        font-size: 11px;
        align-items: center;
        line-height: 1;
        transition: all 1s;
        margin-bottom: 12px;
        &:hover{
            transition: all .5s;
            transform: scale(1.1);
        }
        .title{
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            display: block;
            margin-top: 2px;
            @media(max-width:457px) {
                font-size: 12px !important;
                white-space: nowrap;
                
            }
        }
        img{
            max-width: 32px;
            @media(max-width:457px) {
                max-width: 26px !important;
                
            }
        }
    }
    &__nav_item{
        li{
            line-height: 18px;
            margin-bottom: 14px;
            padding-left: 0;
            a{
                &:hover{
                    text-decoration: underline;
                }
            }
@media (max-width:598px) {
    // line-height: 5px;
    // margin-bottom: 5px;
    
}
          
        }
    }
    .logo{
        max-width: 135px;
        margin-bottom: 15px;
        width: 100%;
        @media(max-width:457px) {
            max-width: 116px;
            
        }
    }
    .row>*{
        @media (max-width: 991px) {
            margin-bottom: 20px;
        }
    }
}
.footer-section__nav_item{
    @media(max-width:457px) {
        display: flex;
        gap: 9px;    
        align-items: center;
    }
  
    li{
        
        a{
            margin: unset;
            font-size: 18px !important;  
            color: $text-color !important;
            display: flex;
            align-items: center;
            text-decoration: unset;

            text-decoration-color:red;
            @media(max-width:630px) {
                font-size: 16px !important;

                
            }
            @media(max-width:457px) {
                font-size: 14px !important;
                display: flex;
                align-items: center;
                
            }

            @media(max-width:398px) {
                font-size: 12px !important;
            }
        }
    }
}
.footer-section__appdownload {
    text-decoration: none !important;
    width: 217px;
    max-width: 217px;

    @media(max-width:457px) {
        width: unset;
        max-width: 100%;  
    }
p{
   
    margin-bottom: 0;
}


}

.footer-item-list{

    ul{
        li{
            padding-left: 0 !important;
        }
    }
display: grid;
 grid-template-columns: repeat(5,1fr);
 justify-content: space-between;
 gap: 12px;

 @media screen and (max-width:1199px) {
    grid-template-columns: repeat(3,1fr);
    
 }
 @media screen and (max-width:798px) {
    grid-template-columns: repeat(2,1fr);
    
 }
 @media screen and (max-width:456px) {
    grid-template-columns: repeat(1,1fr);
    
 }

}
.footer-section__email{

    a{
        text-decoration: none;
    }
}

.links-footer-logo{
    display: flex;
    flex-direction: column;
    gap: 12px;
    @media(max-width:457px) {
        flex-direction: unset;
        
    }
}

.download-app{
    @media(max-width:457px) {
        font-size: 14px !important;
        white-space: nowrap;
        
    }
}
.width-footer-nav{
@media(max-width:457px) {
    min-width: 11px !important;
    
}
}
.company-info{
    @media(max-width:457px) {
        margin-top: 6px !important;
        
    }
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');

@import 'variables.scss';

body {
    font-family: "Roboto", sans-serif;
    color: $text-color;
    font-weight: 400;
}
p{
  margin: 0 !important;
}
img{
  max-width: 100%;
  height: auto;
}
ul{
  list-style: none;
  margin: 0;
  padding: 0;
}
a{
  text-decoration: none;
  color: $text-color;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
  color: inherit;
}
.font-400{
  font-weight: 400;
}
.font-500{
  font-weight: 500 !important;
}
.font-600{
  font-weight: 600 !important;
}
.font-700{
  font-weight: 700;
}
h2{
  font-size: 36px;
  font-weight: 600;
  line-height: 1.17;
  margin-bottom: 15px;
}
.main-container{
  margin-top: 100px;
  min-height: calc(100vh - 350px);
}
.btn{
  color: $text-color;
  font-size: 16px;
  font-weight: 600;
  padding: 5px 20px;
  border-radius: 100px;
  min-width: 120px;
  &:hover{
    text-decoration: none !important;
  }
}
.btn-primary{
  color: $white !important;
  background-color: $green !important;
  border-color: $green !important;
  &:hover,
  &:focus,
  &:active{
    background-color: $green-dark !important;
    border-color: $green-dark !important;
  }
}
.font-14{
  font-size: 14px !important;
}
.font-15{
  font-size: 15px !important;
}
.font-12{
  font-size: 12px;
}
.font-22{
  font-size: 22px !important;
}
.font-24{
  font-size: 24px !important;
}
.font-20{
  font-size: 20px !important;
}
.font-16{
  font-size: 16px !important;
}

.br-100{

  border-radius:100px !important;
}
.br-28{
  border-radius: 28;
}
.m-0{
  margin: 0 !important;

}
.font-18{
  font-size: 18px !important;
}
.page-bg{
  background: linear-gradient(89.58deg, #F1F4F2 0.47%, #EBF4EC 99.74%);
}
.googleAds{
  &.top{
      margin-top: 20px;
      max-width: 980px;
     
  }
}
.text-color-grey{
  color: $text-color !important;
}
.green{
  color:$green;
}
.sky-blue{
  color:$sky-blue;
}
.pinkish-border{
border:3px solid $pinkish;
}
.greenish-border{
  border:3px solid $greenish;
}

.greynish-border{
  border:3px solid $greynish;
}
.redish-border{
  border:3px solid $redish;
}
.green-border{
  border:3px solid $border-green
}
.d-flex{
  display: flex !important;

}
.justify-content-between{
  justify-content:space-between;
}
.align-items-center{
  align-items: center;
}
.gap-4 {
  gap: 1.5rem!important;
}
.pl-0{
  padding-left: 0;
}

.cursor-pointer {
  cursor: pointer;
}
.anchor-link {
  color:#3C91B7;
}
.pt-20{
  padding-top: 20px !important;

}
.pb-6 {
  padding-bottom: 6px !important;
}
.text-right{
  text-align: right;
}

.h-unset {
  height: unset !important;
}
.mb-12{
  margin-bottom: 12px !important;
}
.mr-8{
  margin-right: 8px !important;
}
.margin-t-50{
  @media(max-width:768px) {
    margin-top: 50px !important;
  }
 
}
.br-5{
  border-radius: 5px !important;
}
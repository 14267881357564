@import '../scss/variables.scss';
.form-container{
    padding-top: 60px;
    padding-bottom: 60px;
    margin:0 auto;
    
    @media (min-width: 992px) {
        max-width: 650px;
        
        padding-left: 35px
        
    }
    @media (max-width: 992px) {
        padding-left: 20px;
        padding-right: 20px;
        
    }
    h2{
        font-size: 25px;
        line-height: 1.2;
        font-weight: 600;
        padding-bottom: 49px;
        @media (min-width: 768px) {
            font-size: 28px;
        }
    }
    .form-label{
        font-weight: 600;
        line-height: 1;
        margin-bottom: 12px;
        .subtitle{
            font-size: 10px;
            color: $scorpiont;
            font-weight: 400;
            display: block;
            margin-top: 3px;
        }
    }
    .form-control{
        border-color: $slate-gray;
        border-radius: 4px;
        height: 40px;
        margin-bottom: 5px;
        &:focus{
            box-shadow: 0 0 0 .25rem $green-light;
        }
        &::placeholder{
            font-size: 12px;
        }
    }
    .form-check{
        margin-bottom: 40px;
        .form-check-input{
            margin-top: -1px;
        }
        .form-check-label{
            font-size: 12px;
            line-height: 1.2;
            color: $scorpiont;
            font-style: italic;
        }
    }
    .btn{
        padding: 11px 20px;
        position: relative;
        border-radius: 100px;
        // max-width: 400px;
        .icon-offset{
            max-width: 28px;
            position: absolute;
            left: 18px;
            top: 0;
            bottom: 0;
            margin: auto;
        }
        &.btn-outline-secondary{
            border-color: $gray;
            color: $text-color;
            font-weight: 600;
        }
    }
    .seperator{
        position: relative;
        text-align: center;
        margin: 15px 0;
        span{
            display: inline-block;
            background-color: $white;
            position: relative;
            z-index: 1;
            padding: 0 15px;
        }
        &:after{
            content: "";
            height: 1px;
            background-color: $gray;
            width: 90%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }
    a{
        color: $boston-blue;
        &:hover{
            text-decoration: underline;
            .btn{
                text-decoration: none !important;
            }
        }
    }
    .para-title{
        font-size: 18px;
    }
    .mail-instruction{
        font-size: 18px;
        .title-text{
            font-size: 20px;
            line-height: 1.5;
        }
        ul{
            margin-top: 30px;
            li{
                list-style-type: disc;
                margin-left: 20px;
                line-height: 1.5;
                margin-bottom: 30px;
            }
        }
    }
    .css-b62m3t-container{
        font-size: 14px;
        color: $black;
        margin-bottom: 5px;
        .css-1u9des2-indicatorSeparator{
            background-color: transparent;
        }
        .css-t3ipsp-control{
            border-color: $green;
            box-shadow: none;
            // box-shadow: 0 0 1px $green;
        }
        .css-1xc3v61-indicatorContainer{
            font-size: 14px;
            color: $slate-gray;
        }
        svg{
            width: 15px;
        }
    }
    .css-1nmdiq5-menu{
        font-size: 14px;
        .css-tr4s17-option{
            background-color: $green-light;
            color: $text-color;
            font-weight: 600;
            // &:hover,
            // &:active{
            //     background-color: $green-light !important;
            //     color: $text-color;
            // }
        }
    }
    .btn-verify{
        width: 401px;
        max-width: 100%;
 
    
    }
}

#email-confirm{
    .modal-dialog{
        max-width: 380px;
        .modal-content{
            padding: 0 35px;
            .modal-body{
                font-size: 18px;
                color: $text-color;
                line-height: 1.2;
            }
        }
    }
}
.sidebar-top-spacing
{
    padding-top: 0 !important;
    
}
.signup-flex-basis-remove{
    @media screen and (max-width:558px) {
        .MuiGrid-item{
            flex-basis: 100%;
       }
   } 
    }
   
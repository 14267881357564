@import '../scss/variables.scss';
.challenge-step {
  width: 100%;
  max-width: 840px;
  margin: 30px auto;
  background-color: $white;
  box-shadow: 0px 4px 7.2px 4px #0000001f;
  border-radius: 12px;
  h1 {
    font-size: 28px;
    color: $white;
    font-weight: 500;
    line-height: 1.5;
    background-color: $picton-blue;
    border-radius: 12px 12px 0 0;
    padding: 10px 25px;

    @media screen and (max-width:1234px) {
      font-size: 26px;
      
    }
    @media screen and (max-width:460px) {
      font-size: 20px;
      padding: 10px 19px;
    }
  }
  .box-container {
    background-color: $ecru-white;
    padding: 22px 35px;
    margin: 15px 15px;


    padding: 0px 15px 20px;
    @media screen and (max-width: 598px) {
        margin: 5px 10px;
      padding: 0px 8px 20px;
    }
    h2 {
      font-size: 24px;
      color: $text-color;
      font-weight: 600;
      line-height: 2;

      @media screen and (max-width:1234px) {
        font-size: 22px;
        
      }
      @media screen and (max-width:460px) {
        font-size: 20px;
        
      }
    }
    h3 {
      font-size: 22px;
      color: $text-color;
      font-weight: 600;
      line-height: 1.1;
    }
    p {
      font-size: 20px;
      line-height: 1.2;
      @media  (max-width:460px) {
        font-size: 18px;
        
      }
    }
    .fade-text {
      font-size: 18px;
      color: $dusty-gray;
    }
    .tabs-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      background-color: $white;
      border: 1px solid $silver-chalice;
      border-radius: 12px;
      padding: 10px 20px;
      color: $cinnabar;
      margin-top: 45px;
      margin-top: 20px;
      gap: 12px;
  

         @media (max-width:998px){
     padding: 8px 6px;
      }
    }
    .form-switch {
      background-color: $panache;
      font-size: 22px;
      line-height: 1.15;
      border-radius: 16px;
      padding: 5px 15px;
      margin-bottom: 30px;
      position: relative;
      
      p{
        @media screen and (max-width:698px) {
          font-size: 18px !important;
          
        }
      }
      // input {
      //   background-color: $white;
      //   border-color: $green;
      //   width: 52px;
      //   height: 28px;
      //   position: absolute;
      //   right: 15px;
      //   &:checked {
      //     background-image: url("../../assets/img/green-circle.svg");
      //     background-repeat: no-repeat;
      //     background-size: 20px;
      //     background-position: 90%;
      //   }
      // }
    }

    .home-list {
      display: inline-flex;
      font-size: 20px;
      font-weight: 500;
      padding: 10px 15px;
      border-radius: 12px;
      transition: 0.3s;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      @media(max-width:700px) {
        gap:2px;
        padding: 10px 2px;
        border-radius: 7px;
      }

      &.active,
      &:hover,
      &:focus,
      &:active {
        background-color: $green;
        color: $white;
        img {
          &.in-active {
            display: none;
          }
          &.active {
            display: block;
          }
        }
      }
      img {
        transition: 0.3s;
        @media (max-width:656px) {
          width: 16px;
          
        }
        &.active {
          display: none;
        }
      }
    }
    .form-switch {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
      background: #e5f3e6;
      margin: 0;
      margin-bottom: 20px;
    }
   

    .accordion-home {
      box-shadow: none;
      // background-color: transparent;
      &::before{
        background-color: unset !important;
      }

      .accordion-card {
        background: #e5f3e6;
        border-radius: 16px;
        padding: 0px 15px;

        p{
          @media screen and (max-width:698px) {
            font-size: 18px !important;
            
          }
        }
      }

      .css-eqpfi5-MuiAccordionSummary-content.Mui-expanded {
        margin: 0 !important;
      }

   
    }

    .css-1086bdv-MuiPaper-root-MuiAccordion-root::before {
      content: unset;
    }

    .css-15v22id-MuiAccordionDetails-root {
      padding: 22px 16px 16px;
    }
  }
  .box-container .input-name input {
    border-radius: 9px;
    border-color: $silver-chalice;
    &::placeholder {
      color: $orange-fade;
      font-size: 15px;
    }
    background-image: url("../../assets/img/roundsearch.svg");
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 97% 42%;
  }

  .box-container ul li {
    padding: 12px 6px;
    border-radius: 12px;
    text-align: center;
    background-color: #fff;
    color: $text-color;
  }
  .box-container ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    font-size: 12px;

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media screen and (max-width: 530px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 420px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .search-box-flex {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .search-parent {
    font-weight: 600;
    font-size: 22px;
  }
  .green-text {
    color: $green;
  }
  .blue-text {
    color: $sky-blue;
  }
  .red-boxes {
    border: 3px solid $redish;
  }
  .pink-box {
    border: 3px solid $pinkish;
  }
  .green-box {
    border: 3px solid $greenish;
  }
  .dark-green-box {
    border: 3px solid $green;
  }
  .grey-box {
    border: 3px solid $greynish;
  }
  .home-btn {
    margin-top: 65px;
    width: 100%;
    padding: 12px;
    font-size: 20px;
  }
}

.free-account-box {
  background-color: $orange-dark;
  padding: 14px 18px;
  border-radius: 12px;
  width: 100%;
  max-width: 840px;
  margin: 19px auto;
}
.flex-account {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    text-align: center;
  }
}
.create-btn-home{
flex-basis: 35%;
@media(max-width:1301px) {
  flex-basis: 50%;
  
}

@media(max-width:490px) {
  flex-basis: 50% !important;
  
}

  button{

  
  @media(max-width:1295px) {
    font-size: 16px !important;
    
  }
  @media(max-width:495px) {
    font-size: 13px !important;
    
  }
 
}
}
.free-account-box .flex-account h3 {
  font-size: 20px;
  color: $white-dark;
  font-weight: 600;
  margin: 0;
}
a.btn.btn-white {
  color: $orange-dark;
  background-color: $white;
  border-radius: 7px;
  &:hover {
    background-color: $white;
  }
}
a.btn-login {
  color: $white;
  padding-right: 20px;
}
.page-bg {
  .create-box {
    background: $orange-dark;
    border-radius: 12px;
    color: $white;
    box-shadow: none;
    flex-wrap: nowrap;
    // justify-content: center;
    // @media(max-width:1195px) {
    //   flex-wrap: wrap !important;
    //   justify-content: center;
      
    // }
    h6 {
      font-size: 20px !important;
      flex-basis: 61%;
      @media(max-width:1394px) {
        font-size: 20px !important;
        
      }
      @media(max-width:1295px) {
        font-size: 18px !important;
        
      }
      @media(max-width:995px) {
        flex-basis: 50%;
        
      }
      @media(max-width:595px) {
        font-size: 12px !important;
        
      }
    }
    .create-btn {
      &:hover {
        background-color: $white;
      }
      font-size: 20px;
      font-weight: 800;
      box-shadow: none;
      @media(max-width:1394px) {
        font-size: 16px !important;
        
      }
      @media(max-width:595px) {
        font-size: 12px !important;
        
      }
    
    
    }
  }
}
.home-textfield{
  input{
  
  &::placeholder{
    color:#E94E3299;
  }

}
  svg{
    fill:#E94E32;
  }

  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius:12px;
  }
}
.search-word{
  display: grid;
  justify-content: flex-start;
  grid-template-columns: repeat(5, 1fr);
  @media screen and (max-width:568px) {
    grid-template-columns: repeat(4, 1fr); 
    
  }
  @media screen and (max-width:398px) {
    grid-template-columns: repeat(3, 1fr); 
  }
    
  // }
  // @media screen and (max-width:498px) {
  //   grid-template-columns: repeat(2, 1fr); 
    
  // }
  // @media screen and (max-width:356px) {
  //   grid-template-columns: repeat(1, 1fr); 
    
  // }
  .box-inside-search{

      display: flex;
      flex-direction: column;
      border-radius: 8px;
      width: 100%;
      max-width: 120px;
      background: #fff;
      height: 71px;
  


      @media screen and (max-width:498px) {
     font-size: 16px !important;
        
      }
  }
}

.css-1xwbjn7 .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: rgb(101, 196, 102)!important;
  opacity: 1;
  border: 0px;
}
.css-1xwbjn7 .MuiSwitch-track {
  background-color: rgb(116, 116, 122) !important;
}

.correct-word{
  @media(max-width:1290px) {
    
    font-size: 16px !important;
    
  }
  @media(max-width:860px) {
    
    font-size: 16px !important;
    
  }
  @media(max-width:485px) {
    
    font-size: 13px !important;
    
  }
}
.btn-homepage{
  @media (max-width:460px) {
    font-size: 20px !important;
    
  }
}
.search-grid{
  @media screen  and (max-width:798px){
justify-content: center;
    
  }
}

@import '../scss/variables.scss';

.heading-static {
    @media (min-width: 1024px) {
        top:75px
        
    }
    @media (max-width: 768px) {
        top:65px
        
    }
   
      @media (max-width: 425px) {
        top:65px
        
    }
        
}
.about-heading,.privacy-heading,.term-heading{
  padding-top: 72px;
}


@import "../../scss/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Solway:wght@400;500;700&display=swap");

.cookies-popup{
  padding: 0 !important;
  padding-left: 25px !important;
}

.box-1 {
    background: $sky-light;
    padding: 20px;
    border-radius: 16px;
    &:nth-child(2) {
      background-color: #fff1c1;
    }

    &:nth-child(3) {
      background-color: #e1dbff;
    }
    &:nth-child(4) {
      background-color: #cbfaf9;
    }
    &:nth-child(5) {
      background-color: #ffe4db;
    }
  }
  .cookies-wrapper {
    display: grid;
    gap: 20px;
    grid-template-rows: repeat(5, 1fr);
   
  }
  .cookies-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;

    button{
        font-size: 34px;
        color: #000;
    }
  }

    .cookies-anchor {
      // margin-left: 22px;
      text-decoration: underline;
      font-weight: 500;
      font-size: 14px;
    
    }
  
  .modal-header {
    border-bottom: none;
    P{
        margin-bottom: -4px;
    }
  }


  .sub-heading-cookies{

    font-size: 20px !important;
    padding-bottom: 65px;
    color: #333;
  }

  .form-check-input:checked {
    background-color: unset;
    border-color:transparent ;
}

// .form-check-input:checked[type=checkbox] {
//   --bs-form-check-bg-image: url("../../assets/img/octicon_checkbox.svg");
// }
.cookies-footer {
  display: flex !important;
  justify-content: space-between !important;
  padding: 34px 19px 54px 19px !important;
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    padding:  10px 19px 9px 14px !important;
    
  }
  @media screen and (max-width:578px) {
    flex-direction: column;
    gap: 18px;
   padding:  10px 19px 9px 14px !important;
    
  }

    button {
      padding: 11px 71px 11px 71px;
      border-radius: 100px;
    }
  }

  .manage-box {
    background-color: $white;
    max-width: 520px;
    min-height: 210px;
    padding: 22px;
    text-align: center;
    font-size: 18px;
    border-radius: 4px;
  }
  .btn-manage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:32px;
    margin-top: 60px !important;
    @media screen and (max-width:598px) {
      flex-direction: column;
      gap: unset;
      
    }

    button:nth-child(1){
      white-space: nowrap;
      color: $text-color;
      font-weight: 600;
      text-decoration: underline;

    }
    button {
      // padding: 6px 102px;
      border: none;
      border-radius: 100px;

      &:hover{
        border: none;
        background-color: transparent;
      }
    }
    .btn-anchor {
      font-weight: 600;
      font-size: 16px;
      text-decoration: underline !important;
    }
  }
  .form-check-label {
    padding-bottom: 12px;
  }
 

  
  .cookies-content{
    padding-bottom: 20px;

  }
  
@import '../scss/variables.scss';
.header-banner {
  background-color: #fff !important;
  // padding: 45px 0 45px !important;
  // margin-top: 72px;
  padding: 70px 0px 30px 0px !important;
  h1 {
    font-size: 36px;
    font-weight: 600;
    color: #333333;
    line-height: 1.18;
    margin: 30px auto 22px;
    max-width: 800px;
    max-width: 27ch;
 
    @media (max-width: 768px) {
      font-size: 25.4px;
    
    }
  }
  .quiz-btn {
    font-size: 24px;
    font-weight: 600;
    border-radius: 100px;
    width: 70%;
    background-color: $border-green;
    max-width: 400px;
    padding: 5px 25px;
    @media (max-width: 768px) {
      width: 100%;
      font-size: 18px;
    }
  }
}

.features-item {
  background-color: $green-light;
  padding: 25px 0 !important;
  .main-services {
    h3 {
      font-size: 28px;
      font-weight: 600;
      line-height: 32px;
    }
    p {
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 0;
    }
    .icon {
      img {
        max-width: 75px;
      }
    }
    .row > * {
      margin-bottom: 30px;
    }
  }
  h2 {
    // margin-bottom: 24px;
  }
  .item_listing {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
    justify-content: space-between;
    grid-row-gap: 20px;
    @media (min-width: 992px) {
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: 1fr;
    }

    @media (max-width: 992px) {
      grid-template-columns: 1fr;
    }
    .item-membership{
      padding: 24px 0px 21px 24px  !important;
      background-color: $white;
      border-radius: 24px;
    }
    .item_box {
      background-color: $white;
      border-radius: 24px;
      // padding: 24px 0px 47px 24px  !important;

      .item-inner-box {
        background-color: unset;

        border-radius: unset;
        box-shadow: unset;
      }
      h6{
        font-size: 20px !important;
        @media (max-width:468px) {
          font-size: 16px !important ;
          
        }
      }
      h4 {
        font-size: 20px;
      
        line-height: 1.6;
        font-weight: 500  !important;
        @media (max-width:468px) {
          font-size: 16px !important ;
          
        }
      }
      p {
        font-size: 16px;
        line-height: 1.17;
        @media(max-width:468px)
       {
        font-size: 14px;
       }
        &:nth-of-type(1) {
          margin-bottom: 0;
        }
      }
      .btn {
        font-size: 18px;
        margin-top: 16px;
      }
      .btn-member {
        border-radius: 100px;
        padding: 10px 30px;
      }
    }
  }
  .item-heading {
    // padding-top: 24px;
    padding-bottom: 5px;
  }
}

.video-tutorial {
  background-color: $white-gray;
  padding: 20px 0;
  padding-left: 25px !important;
  padding-right: 21px !important;
  .video-iframe {
    width: 100%;
    max-width: 100%;
  }
  p {
    font-size: 20px !important;
    line-height: 1.2;
    @media (max-width:768px) {
      font-size: 19px !important;
      
    }
    @media (max-width:468px) {
      font-size: 16px !important;
      
    }
  }
  .btn {
    font-size: 14px;
    border-radius: 100px;
    margin-top: 16px;
    margin-bottom: 12px;
  }
  .videoBox,
  iframe {
    border-radius: 20px;
  }
}

.client-testimonial {
  background-color: $green-light;
  padding: 25px 0 25px;

.css-z46ro7-MuiContainer-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

  .swiper-horizontal > .swiper-scrollbar,
  .swiper-scrollbar.swiper-scrollbar-horizontal {
    display: none;
  }

  .swiper-wrapper {
    align-items: stretch;

    .swiper-slide {
      height: auto;
      &.swiper-slide-active {
        .swiper-item {
          padding: 35px;

          .title {
            font-size: 32px;
          }

          img {
            &.quote {
              max-width: 50px;
            }

            &.user-icon {
              width: 60px;
              margin-right: 12px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }

  h2 {
    margin-bottom: 40px;
  }

  .swiper-item {
    background-color: $white;
    border-radius: 24px;
    padding: 28px;
    display: flex;

 
    box-shadow: none !important;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    row-gap: 20px;

    .title {
      font-size: 24px;
      line-height: 1.2;
      margin-bottom: 75px;
    }

    .user-name {
      .name {
        font-weight: 500;
      }

      .destination {
        font-size: 14px;
      }
    }

    img {
      &.quote {
        max-width: 35px;
      }

      &.user-icon {
        width: 60px;
        margin-right: 12px;
      }
    }
  }
}
.client-section{
  @media(max-width:768px) {
    flex-direction: column;
    
  }
}
.landing-subheading{
  font-size: 23px !important;
  @media (max-width:768px) {
    font-size: 22px !important;
    
  }
  @media (max-width:468px) {
    font-size: 18px !important;
    
  }
}
.landing-inner-heading{
  font-size: 22px !important;

}
.spelling-senerio{
  font-size: 23px !important;
  @media (max-width:768px) {
    font-size: 18px !important;
    
  }
}
.item-heading{
  font-size: 23px !important;
}
.spelling-success-img{
  @media (max-width:768px) {
    img{
      width: 45px;
    }
    
  }
}
.features-landing{
  @media (max-width:768px) {
   padding-top: 20px !important;
    
  }
}
.join-para{
  font-size: 30px !important;
  font-weight: 600 !important;
  padding-bottom: 25px;
  @media(max-width:768px) {
    font-size: 24px !important;
    
  }
  @media(max-width:468px) {
    font-size: 22px !important;
    
  }
}
.swiper-pagination-bullet {
  width: 13px; 
  height: 13px; 
}
.swiper-pagination {

  position: unset !important;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
  margin-top: 25px !important;
}


.free-ads{
  font-size: 20px !important;
  font-weight: 500 !important;
  @media (max-width:468px)
{
  font-size: 18px !important;
}}
.free-ads-subsection{
  @media (max-width:468px)
{
  font-size: 14px !important;
}}
.swiper-wrapper{
  h6{
    @media (max-width:468px)
    {
      font-size: 18px !important;
    }}
  }

@import '../scss/variables.scss';


.quiz-step {
    width: 100%;
    max-width: 640px;
    margin: 30px auto;
    background-color: $white;
    box-shadow: 0px 4px 7.2px 4px #0000001f;
    h3 {
        font-size: 28px;
        font-weight: 700;
        line-height: 1.5;
        background-color: $green-light;
        padding: 8px 25px;
        @media screen and (max-width:1234px) {
            font-size: 26px;
            
          }
          @media screen and (max-width:600px) {
            font-size: 20px;
            
          }
    }
    .box-container {
        padding: 14px 35px;
     
    }
 ul{
    display: flex;
    gap: 14px;
    flex-wrap: nowrap;
  
 }


li{
    padding: 3px 17px;
    border-radius: 12px;
    text-align: center;
    border: 3px solid #2A9235;
    flex:1;
    flex-basis: 23px;
    @media(max-width:578px){
        padding: 3px 12px; 
    }
    @media(max-width:535px){
        padding: 3px 10px; 
    }
    @media(max-width:511px){
        padding: 2px 4px; 
    }

}
.grey-box{
    border:3px solid $grey-light;
    flex:1;
}
.red-box{
    border:3px solid #CF232A;
    flex: 1;

}

.text-blackish {
    color: $text-color;
    max-width: 33ch;
    display: flex;
    line-height: 1.4;
    @media screen and (max-width: 768px) {
      font-size: 13px;
    }
  }

  .accent-box {
    background-color:$green;
    border-radius: 16px;
    padding: 8px 15px 12px 12px;
    max-width: 160px;
    width: 100%;
    height: 100%;
    min-height: 96px;
    display: flex;
    flex-direction: column;
    
    @media screen and (max-width: 576px) {
      min-height: 86px;
      padding: 8px 12px 10px 10px;
    }
    
    @media screen and (max-width: 450px) {
      min-height: 80px;
      padding: 6px 10px 8px 8px;
    }

    .slow-speed {
      white-space: nowrap;
      
      @media screen and (max-width: 450px) {
        font-size: 11px !important;
      }
    }
  }
  
.accent-grid-type{
    display: grid;
    row-gap: 20px;
    column-gap: 32px;
    padding-bottom: 20px;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    
    &.us-speech-grid {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      
      @media screen and (max-width: 650px) {
        column-gap: 15px;
      }
      
      @media screen and (max-width: 450px) {
        column-gap: 10px;
      }

      @media screen and (max-width: 650px) {
        column-gap: 15px;
        row-gap: 15px;
      }
      
      @media screen and (max-width: 576px) {
        column-gap: 12px;
        row-gap: 12px;
        padding-left: 5px;
        padding-right: 5px;
      }
      
      @media screen and (max-width: 450px) {
        column-gap: 8px;
        row-gap: 10px;
      }  
    }
    
@media screen and (max-width:650px) {
    column-gap: 11px;
    row-gap: 10px;

}


}
.part-speech-card{

        border: 1px solid $border-green;
        border-radius: 12px;
        overflow: hidden;
        box-shadow: unset;
    

.inner-speech{
    padding: 12px 12px;
}
}
 .accordion{

    .accordion-button:not(.collapsed) {
       color: $black;
         background-color: transparent;
         &:focus{
            outline: none;
            border: none;
            box-shadow: none;
         }
    }
    .accordion-button {
        padding:7px 8px;
    }
    .accordion-body {
        padding:0 6px;
    }
    .accordion-button:not(.collapsed) {
    box-shadow: none;
}

    .accordion-button:after {
        background-image: url("../../assets/img/collapseicon.svg");
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: center center;
      }
   
    .accordion-item:last-of-type>.accordion-header .accordion-button.collapsed {
        outline: none;
        border: none;
        box-shadow: none;
        border-radius: 20px; 
     
    }
    .accordion-item{
       
            border: 1px solid #2A9235;
            border-radius: 12px;
            margin-top: 30px;
        
    }
 }
 .text-para-black{
    color: $text-color;
 }

 .quiz-padding
 {

    input{
      
        border: 1px solid #A9A9A9;
        background-image: url("../../assets/img/keyboard.svg");
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: 97% 42%;
        border-radius: 10px;
        padding: 9px 16px 11px 24px;
        &:focus{
            box-shadow: none;
            outline: none;
            
        }
    }
    .anchor-line a{
        text-decoration: underline;
        text-decoration-color:$red-dark;
        color:$red-dark;
        font-weight: 500;

    }
   
}
.img-icon-next {
        
    position: absolute;
    right: 19px;

} 

.btn-quiz-next{
    padding-right: 35px;
    padding-left: 35px;
    border-radius: 28px;
    font-size: 20px;
    font-weight: 500;
    text-transform: none;
    max-width: 100%;
    width: 165px;
   
    @media(max-width:485px) {
        font-size: 16px !important;
        
    }
    @media(max-width:385px) {
        font-size: 14px !important;
        
    }
}
.btn-previous-quiz{

  border: 1px solid $text-color;
  color:$text-color;
  &:hover{
    border: 1px solid $text-color;
    background-color: transparent;
  }

}
.custom-icon-class  {
    font-size: 12px !important;
}
.accomplish-section
{
    border: 1px solid $border-green;
    border-radius: 10px;
    padding: 7px 6px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.border-red-incorrect{
    border:1px solid $red-dark;
}

.input-border-btm::before, .input-border-btm::after, .input-border-btm:hover:not(.Mui-disabled, .Mui-error):before{
    border-bottom: none;
}
.btn-combo{
    justify-content: flex-end;
    display: flex;
    flex-wrap: wrap;
    gap: 22px;
    @media screen and (max-width:458px) {
        justify-content: center;
        
    }
}
}
.play-inside-img{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: space-between;
    gap: 6px;


}
.flag{
    width: 100%;
    @media(max-width:435px) {
        width: 35px !important;
        
    }
 
  
}
.btn-speaker{
    width: 36px;
    height: 36px;
    @media(max-width:435px) {
        width: 30px;
        
    }
 
}
.btn-slow{
    width: 30px;
    height: 30px;
    @media(max-width:435px) {
        width: 25px;
        
    }
}
.quiz-padding {
    padding-top: 12px;
}

.part-of-speech-text{
    font-size: 22px !important;
    @media (max-width:600px) {
        font-size: 20px !important;
        
    }
    @media (max-width:458px) {
        font-size: 15px !important;
        
    }

}
.btn-combo{
    flex-wrap: nowrap !important;
}
@media (max-width:1199px){
.order-item-2{
    order:2 !important;
}
}
    .quiz-container{

        box-shadow: none !important;
        @media (max-width:568px)  {
        padding: 14px 6px !important;
    } 
    @media (max-width:398px)  {
        padding: 14px 10px !important;
    } 
    @media (max-width:698px) {
        padding: 14px 8px !important;
        
      }
}

  .dash-progress-quiz{
    margin-right: 39px  !important;
    @media (max-width:698px) {
        margin-right: 14px !important;
        
    }
    @media (max-width:418px) {
        margin-right: 10px !important;
        
    }
  }

  .slow-speed{
    @media(max-width:398px) {
        font-size: 12px !important;
        
    }
}

.result-quiz 
{
li {
    padding: 3px 16px !important;
    @media(max-width:552px) {
        padding: 3px 10px !important;
        
    }
    @media(max-width:442px) {
        padding: 3px 8px !important;
        
    }
}
ul{
    @media (max-width:442px) {
        gap: 6px !important;
        
    }
}
}


.text-congrats {
    margin-bottom: 1rem;
    word-wrap: break-word;
    text-align: center;
    
    @media (max-width: 600px) {
      margin-bottom: 0.5rem;
    }
  }
  
  // Animation container styles
  lottie-player {
    max-width: 100%;
    margin: 0 auto;
    display: block;
    
    @media (max-width: 600px) {
      transform: scale(0.8);
    }
    
    @media (max-width: 400px) {
      transform: scale(0.7);
    }
  }
  
  // Profile image responsive styles
  .profile-image-container {
    position: relative;
    margin: 0 auto;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
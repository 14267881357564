.change-psd-page {
    .googleAds {
      // margin-top: 68px !important;
      @media screen and (max-width: 998px) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    .change-psd {
      h4 {
        font-size: 28px !important;
        font-weight: 600;
        line-height: 1.5;
        padding: 8px 25px;
        background-color: rgba(95, 205, 106, 0.3019607843);
        @media screen and (max-width: 1234px) {
            
              font-size: 26px !important;
          
      }
      @media screen and (max-width: 568px) {
            
        font-size: 22px !important;
    
}
       
      }
      button {
        padding: 8px 62px;
        margin: auto;
      }
      .form-label {
        font-size: 16px;
        font-weight: 600;
  
        @media screen and (min-width: 1400) {
          font-size: 20px;
        }
      }
      .box-container {
        padding: 35px 35px 30px 36px;
        margin-top: 0;
      }
    }
    .password-change {
      padding-bottom: 60px;
  
      .padding-bottom-change {
        padding-bottom: 30px;
      }
    }
    ::placeholder {
      font-size: small;
    }
  
    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 9.5px 14px;
    }
    .btn-change {
      text-align: center;
  
      padding-top: 22px;
      padding-bottom: 22px;
  
      button {
        border-radius: 100px;
      }
    }
  }
  // .btn-submit-contact{
  //   @media (max-width:568px) {
  //     font-size: 18px !important;
      
  //   }
  // }
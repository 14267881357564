@import '../scss/variables.scss';

.current-level {
    width: 100%;
    position: relative;
    max-width: 100%;
    background-color: #ffe1a8;
    border-radius: 20px;
    padding: 10px 19px 13px 19px;
    margin-bottom: 16px;

  .slider-current-level {
    width: 100% !important;
    max-width: 100%;
 

}

.img-current-level{
    position: absolute;
    right: 11px;
    top: 6px;

}

}
.words-img{
    position: absolute;
    right: -1px;
    top: -10px;
}
.current-words{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    position: relative;
    text-align: center;
    gap: 12px;
}
.current-words-level{
    padding: 19px 12px 15px 3px;
}




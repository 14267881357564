@import '../../scss/variables.scss';
@import url("https://fonts.googleapis.com/css2?family=Solway:wght@400;500;700&display=swap");
.leftSidebar {
  background: $bg-green-gradient;
  padding: 25px 25px 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 350px);
  overflow: hidden;
  margin-top: -7px;
  @media screen and (max-width:960px) {
    margin-top: -17px;
    
  }
  .panelBox {
    @media (min-width: 992px) {
      max-width: 540px;
      width: 100%;
    }
  }
  h1 {
    font-family: "Solway", serif;
    background-color: $green;
    color: $white;
    font-size: 20px;
    line-height: 1.3;
    font-weight: 700;
    letter-spacing: 0.06em;
    text-align: center;
    padding: 12px 30px;
    border-radius: 16px;
    margin-bottom: 40px;
    @media (min-width: 768px) {
      font-size: 26px;
    }
  }
  .quiz-img {
    position: relative;
    z-index: 2;
  }
  .accountInfo {
    margin-top: 40px;
    h5 {
      line-height: 1.5;
      color: $text-color;
      font-weight: 700;
    }
    ul {
      margin: 24px 0;
      li {
        font-size: 18px;
        font-weight: 500;
        color: $text-color;
        line-height: 1.2;
        // margin-bottom: 15px;
        padding-bottom: 0  !important;
        padding-left: 40px;
        background-image: url("../../../assets/img/check-icon.svg");
        background-repeat: no-repeat;
        background-position: center left;
        background-size: 28px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 85px;
  }
  &:before {
    background-image: url("../../../assets/img/panel-bg1.svg");
    background-repeat: no-repeat;
    width: 210px;
    height: 310px;
    left: -50px;
  }
  &:after {
    width: 315px;
    height: 340px;
    background-image: url("../../../assets/img/polygon-img.svg");
    background-repeat: no-repeat;
    right: -51px;
  }
  .manage-box {
    background-color: $white;
    max-width: 520px;
    min-height: 210px;
    padding: 22px;
    text-align: center;
    font-size: 18px;
    border-radius: 4px;
  }
  .btn-manage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 35px;
    button {
      padding: 6px 102px;
    }
    .btn-anchor {
      font-weight: 600;
      font-size: 16px;
      text-decoration: underline !important;
    }
  }
  .form-check-label {
    padding-bottom: 12px;
  }
  
}
